import React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import classnames from 'classnames';
import { ImageRatio, LayoutType } from '../../../settings/settingsConstants';
import { GroupCardMedia } from '../media/GroupCardMedia';
import { GroupListCardProps } from '../types';
import { GroupCardMeta } from '../meta/GroupCardMeta';
import { GroupCardCTA } from '../cta/GroupCardCTA';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';

import { classes, st } from './GroupListCard.st.css';
import style from './GroupListCard.scss';
import styles from './GroupGridCard.scss';

interface ImageDimensions {
  w: number;
  h: number;
}

const platformImageDimensions = {
  desktop: {
    [ImageRatio.square]: { w: 75, h: 75 },
    [ImageRatio.rectangle]: { w: 142, h: 75 },
  },
  mobile: {
    [ImageRatio.square]: { w: 220, h: 220 },
    [ImageRatio.rectangle]: { w: 160, h: 90 },
  },
};

const getImageDimensions = (
  isMobile: boolean,
  imageRatio: ImageRatio,
): ImageDimensions => {
  const platform = isMobile ? 'mobile' : 'desktop';

  return platformImageDimensions[platform][imageRatio];
};

type Props = InjectedTranslateProps & GroupListCardProps;
export const GroupListCard: React.FC<Props> = ({
  group,
  goToGroup,
  imageRatio,
  hasSpacing,
  mobile,
  cardMetaComponent: MetaComponent, // TODO: Types broken! refactor? polymorphism? should have one interface
  cardActionsComponent: CardActionsComponent,
  showGroupLogo,
  layout,
  groupUrl,
  ...props
}) => {
  const groupWrapper = new GroupWrapper(group);
  const { w, h } = getImageDimensions(mobile, imageRatio);
  const cover = groupWrapper.getScaledLogo(w, h);
  const className = classnames(
    classes[layout],
    props.className,
    {
      [style.mobile]: mobile,
    },
    style.list,
  );
  return (
    <div
      className={st(
        classes.root,
        {
          imageRatio,
          hasSpacing,
          mobile,
          withItemClick: !!goToGroup,
        },
        className,
      )}
      onClick={goToGroup ? goToGroup : undefined}
      data-bi-origin="group_image"
      data-hook="group-list-item"
    >
      <div className={classes.mediaWithMeta}>
        {showGroupLogo && (
          <div className={classes.mediaContainer}>
            <div className={styles.media}>
              <GroupCardMedia
                url={cover}
                layout={LayoutType.list}
                ratio={imageRatio}
                groupUrl={groupUrl}
              />
            </div>
          </div>
        )}
        <MetaComponent
          group={group}
          groupUrl={groupUrl}
          className={classes.listCardMeta}
        />
      </div>
      <CardActionsComponent
        group={group}
        groupUrl={groupUrl}
        className={style.action}
      />
    </div>
  );
};

GroupListCard.defaultProps = {
  cardMetaComponent: GroupCardMeta,
  cardActionsComponent: GroupCardCTA,
};
