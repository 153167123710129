import React from 'react';

import { ImageRatio, LayoutType } from '../../../settings/settingsConstants';
import { classes } from './GroupCardMedia.st.css';
import { GroupCardProps } from '../types';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../../contexts/withAppSettings';

export interface GroupCardMediaProps {
  url: string;
  layout: LayoutType;
  ratio: ImageRatio;
  groupUrl: string;
  goToGroup?: GroupCardProps['goToGroup'];
}
const noop = () => {};

export const GroupCardMediaComponent: React.FC<
  GroupCardMediaProps & WithAppSettingsProps
> = ({ url, goToGroup, appSettings, groupUrl }) => {
  return (
    <a
      tabIndex={-1}
      href={groupUrl}
      onClick={goToGroup}
      data-hook="group-card-media"
      data-bi-origin="group_image"
      style={{ backgroundImage: `url(${url})` }}
      className={classes.image}
    />
  );
};
GroupCardMediaComponent.displayName = 'GroupCardMediaComponent';
GroupCardMediaComponent.defaultProps = {
  goToGroup: noop,
};

export const GroupCardMedia = withAppSettings(GroupCardMediaComponent);
