import React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { NewCard } from 'wix-ui-tpa/NewCard';
import { ImageRatio, LayoutType } from '../../../settings/settingsConstants';
import { GroupCardMedia } from '../media/GroupCardMedia';
import { GroupCardProps } from '../types';
import { GroupCardMeta } from '../meta/GroupCardMeta';
import { GroupCardCTA } from '../cta/GroupCardCTA';

import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import classnames from 'classnames';
import { classes, st } from './GroupGridCard.st.css';
import styles from './GroupGridCard.scss';

const imageDimensions: { [K in ImageRatio]: { w: number; h: number } } = {
  [ImageRatio.square]: { w: 300, h: 300 },
  [ImageRatio.rectangle]: { w: 300, h: 167 },
};

export const GroupGridCard: React.FC<
  InjectedTranslateProps & GroupCardProps
> = ({
  group,
  goToGroup,
  t,
  imageRatio,
  mobile,
  showGroupLogo,
  groupUrl,
  className,
}) => {
  const groupWrapper = new GroupWrapper(group);
  const { w, h } = imageDimensions[imageRatio];
  const cover = groupWrapper.getScaledLogo(w, h);
  return (
    <NewCard
      className={st(
        classes.root,
        { mobile },
        classnames(className, styles.gridCard),
      )}
      stacked={true}
    >
      {showGroupLogo ? (
        <NewCard.Container
          className={classnames(
            styles.mediaContainer,
            styles[`media_${imageRatio}`],
          )}
        >
          <div className={styles.media}>
            <GroupCardMedia
              url={cover}
              layout={LayoutType.grid}
              ratio={imageRatio}
              groupUrl={groupUrl}
              goToGroup={goToGroup}
            />
          </div>
        </NewCard.Container>
      ) : null}
      <NewCard.Container className={classes.info}>
        <div
          data-hook="group-card-info"
          onClick={goToGroup}
          className={styles.info_grid}
        >
          <GroupCardMeta
            group={group}
            groupUrl={groupUrl}
            className={classes.gridCardMeta}
          />
          <GroupCardCTA group={group} groupUrl={groupUrl} />
        </div>
      </NewCard.Container>
    </NewCard>
  );
};
GroupGridCard.displayName = 'GroupGridCard';
